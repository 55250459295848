import { ArrayInput, AutocompleteInput, FormDataConsumer, FormTab, ImageField, TextField, TextInput } from "react-admin";
import SimpleFormIndexableIterator from "../../../utils/components/SimpleFormIndexableIterator/SimpleFormIndexableIterator";
import { Box } from "@material-ui/core";
import { imageTypes } from "../Types/imageTypes";
import ImageInput from "../../../utils/components/ImageInput";
import * as React from "react";
import DateField from "../../../utils/components/DateField";
import LazyImageField from "../../../utils/components/LazyImageField";

export default ( props ) => <FormTab label="Standardbilder" {...props}>
  <ArrayInput source="images" label={"Standardbilder"} fullWidth>
    <SimpleFormIndexableIterator>
      <FormDataConsumer>
        {( { getSource, scopedFormData } ) => {
          return <>
            <Box display={"flex"} flexDirection={"row"} fullWidth>
              <Box flex={1} mr="0.5em">
                <AutocompleteInput
                  source={getSource( "imageType" )}
                  label={"Bildtyp"}
                  allowEmpty={true}
                  emptyText={"Bitte wählen"}
                  required={true}
                  fullWidth
                  choices={imageTypes}/>


                <ImageInput
                  source={getSource( "file" )}
                  label={""}
                  multiple={false}>
                  <LazyImageField
                    source={"remoteFileName"}
                    title={"localFileName"}
                    onClick={props.onImageClick}
                    loading={"lazy"}
                  />
                </ImageInput>
                <span style={{ fontSize: "0.875rem" }}>
                  {scopedFormData?.hasOwnProperty('file' ) && scopedFormData['file'] && <>Aufgenommen am <DateField source={getSource( "created" )} options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit', second: '2-digit' }} fullWidth/></>}
                </span>
              </Box>
            </Box>
          </>
        }}
      </FormDataConsumer>
    </SimpleFormIndexableIterator>
  </ArrayInput>
</FormTab>
