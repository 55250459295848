import * as React from "react";
import { CloneButton } from "react-admin";
import { unset } from "lodash";

const objectKeys = [
  "@type",

  "testContract",
  "flexContract",

  "contractClientAddress",
  "billingClientAddress",
  "leistungsadresse",

  "services",
  "accessories",
  "zubehoerArray",

  "carDetails",
  "carModel",
  "carFin",
  "licensePlate",
  "manufacturer",
  "bereifung",
  "zusatzBereifung",
  "felge",
  "zusatzFelge",
  "tuev",
  "angemeldet",
  "verzoegerteAbgabe",

  "vorholung",

  "datum",
  "pickupDate",
  "pickupDateRaw",
  "pickupTime",
  "pickupTimeFrom",
  "pickupTimeTo",
  "pickupLocation",
  "pickupLocationIsSet",
  "abholinfos",

  "bringdatum",
  "deliveryDate",
  "deliveryDateRaw",
  "deliveryTime",
  "deliveryTimeFrom",
  "deliveryTimeTo",
  "deliveryLocation",
  "deliveryLocationIsSet",
  "bringinfos",

  "serviceAddresses",
  "leasingProtocols",
  "leistungsadresse",
  "billing_addresses",
  "contractType",
  "leasingVertragsnummer",
  "infoBackend",
  "abholinfos",
  "bringinfos",
]

export const CloneContractButton = (props) => {
  const { record, ...rest } = props;
  if( !record ) {
    return null;
  }

  const _clone = { };
  objectKeys.forEach( key => {
    if( record[ key ]) {
      _clone[key] = record[ key ];

      if(key === "serviceAddresses" ) {
        _clone[key] = record[ key ].map( serviceAddress => {
          const __clone = { ...serviceAddress };
          unset( __clone, '@id' );
          unset( __clone, '@type' );
          return __clone
        });
      }
    }
  })

  _clone.neumerker = '1';

  return <CloneButton record={_clone} target="_blank"  {...rest} />;
};
