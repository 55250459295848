import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { Field, useForm } from "react-final-form";
import React from "react";

registerLocale("de", de);
setDefaultLocale("de");

export default ({ fieldName = "deliveryDate" }) => {
  const { change, batch } = useForm();
  const parseDate = (date) => {
    if (!date) return null;
    if (date instanceof Date) return date;
    if (typeof date === "string") {
      const [day, month, year] = date.split(".");
      if (!day || !month || !year) return null;
      const parsedDate = new Date(`${year}-${month}-${day}`);
      parsedDate.setHours(12);
      return parsedDate;
    }
    return null;
  };

  return (
    <Field name={fieldName}>
      {({ input: { value, onChange } }) => (
        <DatePicker
          className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputMarginDense MuiFilledInput-inputMarginDense"
          dateFormat="dd.MM.yyyy"
          selected={parseDate(value)}
          onChange={(date) => {
            if (!date) return;
            date.setHours(12);
            const formattedDate = date.toLocaleDateString("de-DE");
            onChange(formattedDate);
            batch(() => {
              change(fieldName, formattedDate);
              change(`${fieldName}Raw`, date);
            })
          }}
        />
      )}
    </Field>
  );
};
