module.exports = {
  de: {
    ra: {
      sort: {
        'ASC': 'AUFSTEIGEND',
        'DESC': 'ABSTEIGEND',
      },
    },
    resources: {
      protocols: {
        fields: {
          _id: 'Datum der Erstellung',
          auftrag: {
            auftraggeber: {
              firma: 'Firma'
            },
            datum: 'Abholdatum'
          }
        }
      }
    },
    custom: {
      page: {
        loading: 'Änderungen werden verarbeitet...'
      },
      message: {
        loading: 'Die Änderungen werden verarbeitet, bitte haben Sie etwas Gedult.',
        success: 'Änderung erfolgreich'
      },
      loadingMessage: {
        loading: 'Daten werden geladen'
      },
    },
    protocolImages: {
      STARTED: 'Abholung',
      SUBMITTED: 'Zustellung',
      submitter_vorne_links: 'Abholung Vorne links',
      submitter_vorne_rechts: 'Abholung Vorne rechts',
      submitter_hinten_rechts: 'Abholung Hinten rechts',
      submitter_hinten_links: 'Abholung Hinten links',
      submitter_dach: 'Abholung Dach',
      submitter_felge_vorne_links: 'Abholung Felge Vorne links',
      submitter_felge_hinten_links: 'Abholung Felge Hinten links',
      submitter_felge_hinten_rechts: 'Abholung Felge Hinten rechts',
      submitter_felge_vorne_rechts: 'Abholung Felge Vorne rechts',
      submitter_instrumententafel: 'Abholung Instrumententafel',
      submitter_kfz_schein: 'Abholung KFZ-Schein',
      submitter_kfz_schein_temp: 'Abholung Rotes Fahrzeugscheinheft',
      submitter_sicherheits_set: 'Abholung Sicherheits-Set',
      submitter_tire_fit: 'Abholung Tirefit-Kit/Reserverad',
      submitter_schluessel: 'Abholung Schlüssel+Bordbuch',
      submitter_serviceheft: 'Abholung Serviceheft',
      submitter_innenraum_vorne_links: 'Abholung Innenraum Vorne links',
      submitter_innenraum_vorne_rechts: 'Abholung Innenraum Vorne rechts',
      submitter_innenraum_hinten: 'Abholung Innenraum Hinten',
      submitter_insulating_mat: 'Abholung Motorraum-Dämmmatte',
      submitter_wheelSet: 'Abholung Radsatz',
      submitter_fuelCard: 'Abholung Tankkarte',
      submitter_chargingCable: 'Abholung Ladekabel',
      submitter_serviceBooklet: 'Abholung Serviceheft',
      submitter_additional_trunk: 'Abholung Kofferraumabdeckung',
      receiver_vorne_links: 'Zustellung Vorne links',
      receiver_vorne_rechts: 'Zustellung Vorne rechts',
      receiver_hinten_rechts: 'Zustellung Hinten rechts',
      receiver_hinten_links: 'Zustellung Hinten links',
      receiver_dach: 'Zustellung Dach',
      receiver_felge_vorne_links: 'Zustellung Felge Vorne links',
      receiver_felge_hinten_links: 'Zustellung Felge Hinten links',
      receiver_felge_hinten_rechts: 'Zustellung Felge Hinten rechts',
      receiver_felge_vorne_rechts: 'Zustellung Felge Vorne rechts',
      receiver_instrumententafel: 'Zustellung Instrumententafel',
      receiver_kfz_schein: 'Zustellung KFZ-Schein',
      receiver_kfz_schein_temp: 'Zustellung Rotes Fahrzeugscheinheft',
      receiver_tire_fit: 'Zustellung Tirefit-Kit/Reserverad',
      receiver_sicherheits_set: 'Zustellung Sicherheits-Set',
      receiver_schluessel: 'Zustellung Schlüssel+Bordbuch',
      receiver_serviceheft: 'Zustellung Serviceheft',
      receiver_innenraum_vorne_links: 'Zustellung Innenraum Vorne links',
      receiver_innenraum_vorne_rechts: 'Zustellung Innenraum Vorne rechts',
      receiver_innenraum_hinten: 'Zustellung Innenraum Hinten',
      receiver_insulating_mat: 'Zustellung Motorraum-Dämmmatte',
      receiver_wheelSet: 'Zustellung Radsatz',
      receiver_additional_trunk: 'Zustellung Kofferraumabdeckung',
      receiver_fuelCard: 'Zustellung Tankkarte',
      receiver_chargingCable: 'Zustellung Ladekabel',
      receiver_serviceBooklet: 'Zustellung Serviceheft',
      fuelCard: 'Tankkarte',
      radsatz: 'Radsatz',
      wheelSet: 'Radsatz',
      chargingCable: 'Ladekabel',
      serviceBooklet: 'Serviceheft',
    },
    avis: {
      coordination: {
        pickup: 'Abholung',
        delivery: 'Zustellung',
        swap: 'Tausch',
        pickup_swap: 'Abholung und Tausch',
      }
    }
  }
};
