import { Button, FunctionField, List, ListContextProvider, ResourceContextProvider, TextField, TopToolbar, useListContext, useListController } from 'react-admin';
import DefaultPagination from '../../utils/DefaultPagination';

import React, { Fragment, useCallback, useState } from "react";
import { Box, Checkbox, Chip, makeStyles, Tooltip } from "@material-ui/core";
import { avisFilter, avisFilterDefaultValues } from "./components/AvisFilter";
import EntityLink from "../../utils/components/EntityLink";
import { useTranslate } from "ra-core";
import { SetContractTypeButton } from "./components/SetContractTypeButton";
import { ChangeColorButton } from "./components/ChangeColorButton";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { AddNotesButton } from "./components/AddNotesButton";
import { LinkContractsButton, UnlinkContractsButton } from "./components/LinkContractsButton";
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { CreateBundleButton } from "./components/CreateBundleButton";
import { orange } from "../../utils/components/ListTags";
import { RemoveBundleButton } from "./components/RemoveBundleButton";

const AvisActions = ( props ) => (
  <TopToolbar>
  </TopToolbar>
);

export function translateStatus( status ) {
  const statusMap = {
    'pending': 'Offen',
    'first_contact': 'Erstkontakt',
    'confirmed': 'Bestätigt',
    'awaiting_response': 'Rückmeldung ASP abwarten',
    'awaiting_dispo': 'Rückmeldung Dispo',
    'doing': 'In Bearbeitung'
  }

  return statusMap[ status ] || status;
}

const useStyles = makeStyles( theme => ( {
  root: {
    width: "100%",
    margin: "1rem auto",
  },
  tableContainer: {
    // overflowX: "initial",
    // overflowY: "auto",
    // maxHeight: "calc(100vh - 250px)",
  },
  actionBar: {
    position: "absolute",
    top: 0,
    transform: "translateY(-100%)",
    color: "#2196f3",
    background: "rgb(231, 243, 253)",
    justifyContent: "space-between",
    width: "100%",
    zIndex: "1000",
    display: "flex",
    alignItems: "center",
    height: "64px",
    transition: "all 0.3s",
    overflow: "hidden"
  },
  tableHeadCell: {
    padding: "6px 24px 6px 16px",
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  responsiveBox: {
    padding: "0 1rem 0 0",
    fontSize: "0.875rem",
    maxWidth: "1280px",
    [theme.breakpoints.down('lg')]: {
      maxWidth: '860px',  // Set the max-width for small screens
    },
  },
  gridContainer: {
    borderBottom: '1px solid rgb(224,224,224)',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
} ) )

const DeselectTextLink = ( { onClick, listSize } ) => {
  let text = "Ein Element ausgewählt";
  if( listSize > 1 ) {
    text = `${listSize} Elemente ausgewählt`;
  }
  return <Box display={"flex"} alignItems={"center"}>
    <Button onClick={onClick} children={<ClearIcon />} variant="text" />
    {text}
  </Box>
}

const groupByBundle = ( contractList ) => {
  const groupedList = []

  for( const contract of contractList ) {
    if( !contract ) {
      continue
    }

    if( !contract.avis?.bundle?.id ) {
      groupedList.push( contract )
      continue
    }

    const id = contract.avis.bundle.id
    let group = groupedList.find( (element) => element.groupId === id )
    if( !group ) {
      const group = {
        groupId: id,
        contracts: [contract]
      }
      groupedList.push(group);
      continue
    }

    group.contracts.push( contract )
  }

  const flatGroupedList = []
  for( const item of groupedList ) {
    if( item.contracts ) {
      for( const contract of item.contracts ) {
        flatGroupedList.push( contract )
      }
    } else {
      flatGroupedList.push( item )
    }
  }

  return flatGroupedList
}

const AvisListView = ( props ) => {
  const { ids, data } = useListContext();
  const classes = useStyles();
  const [selectedContracts, setSelectedContracts] = useState( new Set() );

  const records = groupByBundle( ids.map( ( id ) => data[ id ] ) );

  const listItems = records.map( ( record ) => {
    return <AvisListItem record={record} key={record.id} selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts} {...props}/>
  } )

  const getHeight = (size ) => {
    if( size === 0 ) {
      return { height: '0' }
    }

    return {}
  }

  return <Box>
    <Box className={classes.actionBar} style={getHeight(selectedContracts.size)}>
      <DeselectTextLink onClick={() => setSelectedContracts(new Set())} listSize={selectedContracts.size} />

      <Box className={classes.responsiveBox}>
        {selectedContracts.size > 0 && <Box display={"flex"}>
          <CreateBundleButton selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts} />
          <ChangeColorButton displayLabel={true} selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts}/>
        </Box>}
      </Box>
    </Box>
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell} width={"25px"} style={{padding: "6px 0 6px 12px"}}></TableCell>
            <TableCell className={classes.tableHeadCell}>Kunde</TableCell>
            <TableCell className={classes.tableHeadCell}>Auftragsnummer</TableCell>
            <TableCell className={classes.tableHeadCell}>Kennzeichen</TableCell>
            <TableCell className={classes.tableHeadCell}>Fahrer</TableCell>
            <TableCell className={classes.tableHeadCell}>Abstimmung</TableCell>
            <TableCell className={classes.tableHeadCell}>Stellplatz</TableCell>
            <TableCell className={classes.tableHeadCell}>Datum</TableCell>
            <TableCell className={classes.tableHeadCell}>Uhrzeit</TableCell>
            <TableCell className={classes.tableHeadCell}>Status</TableCell>
            <TableCell className={classes.tableHeadCell}>Bemerkung</TableCell>
            <TableCell className={classes.tableHeadCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listItems}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

const ParkingSpaceListField = ( { record } ) => {
  if( record?.avis?.parkingSpace && record.avis?.parkingSpaceNotificationScheduled ) {
    return 'Ja'
  }

  if( record.avis?.contractDetails?.pickupLocationIsParkingSpace ) {
    return 'Offen'
  }

  return '';
}

const AvisListItem = ( { record, ...props } ) => {
  const classes = useStyles();
  const { selectedContracts, setSelectedContracts, ...rest } = props;

  const handleCheckboxChange = useCallback( ( e ) => {
    const newSelectedContracts = new Set( selectedContracts );
    if( e.target.checked ) {
      newSelectedContracts.add( record['@id'] );
    } else {
      newSelectedContracts.delete( record['@id'] );
    }
    setSelectedContracts( newSelectedContracts );
  }, [record, selectedContracts, setSelectedContracts] );

  return <TableRow class={classes.tableRow}>
    <TableCell style={{padding: "6px 0 6px 12px"}}>
      <Checkbox onChange={handleCheckboxChange} checked={selectedContracts.has( record['@id'] )} style={{padding: 0}}/>
    </TableCell>

    <TableCell>
      <ListField field={"customer"} label={"Kunde"} record={record} {...props} />
    </TableCell>

    <TableCell>
      <Box display={"flex"} alignItems={"center"}>

      {record.avis?.bundle?.id && <Tooltip title={`Paket ${record.avis.bundle?.id}`} key={`P${record.avis.bundle?.id}`} arrow interactive>
        <Chip
          label={`P${record.avis.bundle?.id}`}
          style={{
            backgroundColor: "orange",
            color: "white",
            marginRight: "2px",
          }}
          size="small"
        />
      </Tooltip>}
      <EntityLink record={record} style={{ minWidth: 'auto' }} title={`${record.auftragsnummer} in AE anzeigen`} target={"_blank"}>
        <ListField field={"orderNumber"} record={record} {...props} />
      </EntityLink>
      {record.avis?.linkedAvis && <EntityLink record={record.avis?.linkedAvis?.contractDetails?.contract}
                                              style={{ minWidth: 'auto' }}
                                              title={`${record.avis?.linkedAvis.contractDetails.auftragsnummer} in AE anzeigen`}
                                              target={"_blank"}>
        <TextField record={record} source={"avis.linkedAvis.contractDetails.orderNumber"}/>
      </EntityLink>}
      </Box>
    </TableCell>

    <TableCell>
      <ListField field={"licensePlate"} label={"Kennzeichen"} sortBy={"kennzeichen"} record={record}{...props} />
    </TableCell>

    <TableCell>
      <ListField field={"driver"} label={"Fahrer"} sortBy={"abholer.kuerzel"} record={record} {...props} />
    </TableCell>

    <TableCell>
      <ListFieldCoordination record={record} label={"Abstimmung"} {...props} />
    </TableCell>

    <TableCell>
      <ParkingSpaceListField record={record} />
    </TableCell>

    <TableCell>
      <FunctionField label={"Datum"} record={record} render={record => {
        if( record.avis?.coordination === "swap" || record.avis?.coordination === "delivery" ) {
          return record.avis?.contractDetails?.deliveryDate
        }

        if( record.avis?.coordination === "pickup" ) {
          return record.avis?.contractDetails?.pickupDate
        }

        if( record.avis?.coordination === "pickup_swap" ) {
          return record.avis?.contractDetails?.pickupDate+" / "+record.avis?.contractDetails?.deliveryDate
        }

        if( record.contractType === "PICKUP" ) {
          return record.pickupDate
        }
        if( record.contractType === "DELIVERY" ) {
          return record.deliveryDate
        }
      }}/>
    </TableCell>


    <TableCell>
      <FunctionField label={"Uhrzeit"} record={record} render={record => {
        if( record.avis?.coordination === "swap" || record.avis?.coordination === "delivery" ) {
          return record.avis?.contractDetails?.deliveryTime
        }

        if( record.avis?.coordination === "pickup" ) {
          return record.avis?.contractDetails?.pickupTime
        }

        if( record.avis?.coordination === "pickup_swap" ) {
          return record.avis?.contractDetails?.pickupTime+" / "+record.avis?.contractDetails?.deliveryTime
        }

        if( record.contractType === "PICKUP" ) {
          return record.pickupTimeFrom+" - "+record.pickupTimeTo
        }

        if( record.contractType === "DELIVERY" ) {
          return record.deliveryTimeFrom+" - "+record.deliveryTimeTo
        }
      }}/>
    </TableCell>

    <TableCell style={{backgroundColor: record.avis?.color ?? ''}}>
      {translateStatus( record.avis?.status ?? 'pending' )}
    </TableCell>

    <TableCell style={{backgroundColor: record.avis?.color ?? ''}}>
      <Box display={"flex"} alignItems={"center"}>
        {record.avis?.importantNote && <ErrorIcon style={{color: 'red'}}/>}&nbsp;
        <TextField source="avis.note" label={"Bemerkung"} record={record} sortable={false}/>
      </Box>
    </TableCell>

    <TableCell style={{backgroundColor: record.avis?.color ?? ''}}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <AddNotesButton record={record}/>
        <LinkContractsButton record={record}/>
        <UnlinkContractsButton record={record}/>
        <ChangeColorButton record={record}/>
        <RemoveBundleButton record={record}/>
      </Box>
    </TableCell>
  </TableRow>
}

const AvisList = ( props ) => {
  const perPage = parseInt( process.env.REACT_APP_LIST_ITEMS )

  const listControllerProps = {
    basePath: '/avis_contracts',
    hasCreate: false,
    resource: 'contracts',
    perPage: perPage,
    exporter: false,
    syncWithLocation: true,
    disableSyncWithLocation: false,
    filter: {
      isAvisContract: true,
      hideLinkedAvis: true,
      custom__abgeschlossen: false,
      provider__disable_bundles: true,

    },
    sort: {
      field: 'datum',
      order: 'ASC'
    },
  };

  const controllerProps = useListController( listControllerProps );


  return (
    <ResourceContextProvider value="contracts">
      <ListContextProvider value={controllerProps}>
        <List
          sort={{ field: 'datum', order: 'ASC' }}
          perPage={perPage}
          pagination={<DefaultPagination perPageArray={[10, 25, 50, 100, 200, 500, 1000]}/>}
          title={"Avisierung"}
          filters={avisFilter}
          filterDefaultValues={avisFilterDefaultValues}
          actions={<AvisActions/>}
          bulkActionButtons={<AvisBulkActionList />}
          syncWithLocation
          basePath="/avis_contracts"
          {...controllerProps}
        >
          <AvisListView {...props} />
        </List>
      </ListContextProvider>
    </ResourceContextProvider>
  );
}

const ListField = ( { field, ...props } ) => {
  const record = props.record;

  const map = {
    customer: {
      contract: "contractClientAddress.kuerzel",
    },
    orderNumber: {
      contract: "auftragsnummer",
    },
    licensePlate: {
      contract: "licensePlate",
      showLinkedData: true,
    },
    driver: {
      contract: "driver.kuerzel",
    },
    status: {
      contract: "avis.status",
    },
  }


  let linkedField = false;
  if( map[ field ].showLinkedData && record?.avis?.linkedAvis ) {
    linkedField = `avis.linkedAvis.contractDetails.${field}`
  }

  return <>
    <TextField source={record?.avis ? `avis.contractDetails.${field}` : map[ field ].contract} record={record}/>
    {linkedField && <>&nbsp;/ <TextField source={linkedField} record={record} /></>}
  </>
}

const AvisBulkActionList = () => {
  return <>
    <CreateBundleButton />
    <ChangeColorButton displayLabel={true}/>
  </>
}

const ListFieldCoordination = ( { record } ) => {
  const translate = useTranslate();

  if( record?.avis?.coordination ) {
    return translate( `avis.coordination.${record.avis?.coordination}` )
  }

  if( record?.avis?.linkedAvis ) {
    return translate( 'avis.coordination.swap' )
  }

  if( record?.contractType ) {
    return translate( 'avis.coordination.'+record.contractType.toLowerCase() )
  }

  return <SetContractTypeButton record={record} />
}


export { AvisList };
